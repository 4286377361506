<template>
  <base-modal
    container-class="fixed z-50 inset-0 overflow-y-auto"
    overlay-class="bg-gray-900 opacity-60"
    popup-class="bg-white text-left overflow-hidden shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full md:max-w-2xl lg:max-w-4xl xl:max-w-6xl"
  >
    <template #modalHeader>
      <header class="px-2.5 py-2 bg-custom-gray-2 flex justify-between items-center">
        <span class="text-black text-sm font-semibold">
          Deal Summary - {{ dealSummaryData.contractNumber }}
        </span>
        <base-svg
          class="h-5 w-5 text-primary-gray inline-block hover:text-gray-900 cursor-pointer"
          src="icons/cross.svg"
          :svg-attributes="{
            class: 'h-full w-full'
          }"
          tag="span"
          @click="handleCancel()"
        />
      </header>
    </template>
    <template #modalBody>
      <div class="px-6 py-5">
        <div
          v-if="isDealSummaryLoading"
          class="my-40 text-center"
        >
          <base-svg
            class="h-4 w-4 mr-1 text-primary-red inline-block"
            src="icons/circleSpinner.svg"
            tag="span"
          />
          Loading ...
        </div>
        <brand-assurance-deal-summary v-else />
      </div>
    </template>
  </base-modal>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import useDealSummary from './dealSummary.js';

export default {
    name: 'BrandAssuranceDealSummaryModal',

    components: {
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        BrandAssuranceDealSummary: defineAsyncComponent(() => import('./BrandAssuranceDealSummary.vue'))
    },

    emits: [
        'hide'
    ],

    setup (props, { emit }) {
        const { dealSummaryData, isDealSummaryLoading } = useDealSummary();

        const handleCancel = () => {
            emit('hide');
        };

        return {
            dealSummaryData,
            isDealSummaryLoading,
            handleCancel
        };
    }
};
</script>
